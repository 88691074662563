import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { QuestionHeader } from '../FormFieldTypes/QuestionHeader';
import { Text } from '../../Components/General/Text';
import { BrancherList } from '../../Components/General/BrancherList';
import { ProgramPositions } from '../../QualifyingForm/ProgramPositionOptions';
import { IStoreTypes } from '../../store/storeTypes';
import { GetSharedResourceLink } from '../../utils/LinkUtils';

const useStyles = makeStyles({
  exampleImage: {
    maxHeight: 250,
  },
  marginBottom: {
    marginBottom: 30,
  },
});

export const ValuesInfo = () => {
  const sessionPosition = useSelector((state: IStoreTypes) => state.user?.sessionPosition);
  const roleLabels = useSelector((state: IStoreTypes) => state.user?.roleLabels);
  const isMentee = sessionPosition === ProgramPositions.mentee;
  const otherPosition = isMentee ? ProgramPositions.mentor : ProgramPositions.mentee;
  const partnerPosition = roleLabels[otherPosition];
  const styles = useStyles();

  return (
    <>
      <Grid item xs={12} className={styles.marginBottom}>
        <QuestionHeader>
          Next, we will be asking you a series of questions to measure your values
        </QuestionHeader>
      </Grid>
      <Grid item xs={12} container alignItems="center" justify="space-between">
        <Grid item xs={12} md={6}>
          <Text variant="md" marginTop={10} fontWeight={600}>
            We ask about your values to help us:
          </Text>
          <BrancherList
            variant="sm"
            fontWeight={500}
            listItems={[
              `Match you with an ideal ${partnerPosition}`,
              'Provide you with values insights (see example screen)'
            ]}
         />
        </Grid>
        <Grid item xs={12} md={6}>
          <img src={GetSharedResourceLink('values-insights-example.png')} alt="Personality and Values Insights example" className={styles.exampleImage} />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <Text variant="md" fontWeight={600} marginTop={20}>
              Tips:
            </Text>
          </Grid>
          <BrancherList
            variant="sm"
            fontWeight={500}
            listItems={[
              'Answer honestly, even if you don\'t like the answer',
              'This is not a test, there are no right or wrong answers',
              'Reflect on what truly resonates with you',
              'Your spontaneous answer is usually the most accurate',
            ]}
          />
          <Grid item xs={12}>
            <Text variant="sm" fontWeight={600} color="purple">
              Some questions may feel repetitive. 😬 So please bear with us, this is necessary to accurately measure your values.
            </Text>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
