import * as React from 'react';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import { BrancherDottedDivider } from '../../Components/General/BrancherDivider';
import { BrancherRouterLink } from '../../Components/General/BrancherRouterLink';
import {
  defaultPasswordRules,
  PasswordValidation,
} from '../../Components/General/PasswordValidation';
import { Text } from '../../Components/General/Text';
import { NextButton } from '../../Components/InputFields/BrancherButton';
import { BrancherTextField } from '../../Components/InputFields/BrancherTextField';
import { GetRoute } from '../../Components/Routing';
import { signupRedirectUri } from '../../consts/cognito';
import {
  SaveUserInfo,
  UtilCheckUserExists,
  UtilGetCompanyInfo,
  UtilGetProgramName,
  UtilSignUpUser,
} from '../../store/actions/';
import { IUtilSignUpUserResponse } from '../../store/actions/UserInfoActions';
import { IStoreTypes } from '../../store/storeTypes';
import { ESessionAttributes, getSessionAttribute, setSessionAttribute } from '../../utils/cookies';
import { IValidationRules, simpleValidatePassword } from '../../utils/validators/validatePassword';
import { validatePhoneNumber } from '../../utils/validators/validatePhoneNumber';
import { BackSplashImagery } from '../Login/BackSplashImagery';
import { SSOTypes } from '../Login/SSOSignIn';
import { BrancherTnCs } from './BrancherTnCs';
import { SSOSignUp } from './SSOSignUp';
import { BrancherPhoneNumberField } from '../../Components/InputFields/BrancherPhoneNumberField';
import { SAMLSignUp } from './SAMLSignUp';
import { UpdatableCompanyInfo } from '../../store/reducers/UserInfoReducer';

export const SignUp = () => {
  const googleAuth = useSelector((state: IStoreTypes) => state.user?.googleSignOn);
  const [fName, setFName] = React.useState<string>('');
  const [lName, setLName] = React.useState<string>('');
  const [organisation, setOrganisation] = React.useState<string>('');
  const [email, setEmail] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [phoneNumber, setPhoneNumber] = React.useState<string>('');
  const [agreeToPolicies, setAgreeToPolicies] = React.useState<boolean>(false);
  const [signedUp, hasSignedUp] = React.useState<boolean>(false);
  const [alreadySignedUpToProgram, setAlreadySignedUpToProgram] = React.useState<boolean>(false);
  const [isPreviousUser, setIsPreviousUser] = React.useState<boolean>(false);
  const [simpleJoin, setSimpleJoin] = React.useState<boolean>(true);
  const [waiting, updateWaiting] = React.useState<boolean>(false);
  const [checkedEmail, setCheckedEmail] = React.useState<boolean>(false);
  const [isSSO, setIsSSO] = React.useState<boolean>(false);
  const [isSAMLSSO, setIsSAMLSSO] = React.useState<boolean>(false);
  const [samlRedirectUri, setSAMLRedirectUri] = React.useState<string>('');
  const [validatorAnchor, setValidatorAnchor] = React.useState(null);
  const [fieldErrors, setFieldErrors] = React.useState<string[]>([]);
  const [userId, setUserId] = React.useState<string>('');
  const [apiError, setApiError] = React.useState<string>('');
  const [redirectToVerifyConfirmation, setRedirectToVerifyConfirmation] = React.useState<boolean>(
    false,
  );
  const [passwordPolicy, setPasswordPolicy] = React.useState<IValidationRules>(
    defaultPasswordRules,
  );
  const dispatch = useDispatch();
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const { search } = useLocation();

  const params = new URLSearchParams(search);
  const companyId = params.get('cId');
  const programId = params.get('pId') || getSessionAttribute(ESessionAttributes.SIGNUP_PROGRAM_ID);
  if (!!programId) {
    setSessionAttribute(ESessionAttributes.SIGNUP_PROGRAM_ID, programId);
  }
  const ssu = params.get('ssu');
  const isSSU: boolean = ssu !== null;

  React.useEffect(() => {
    if (companyId && !organisation) {
      dispatch(
        UtilGetCompanyInfo(companyId, (response) => {
          if (response.success) {
            dispatch(
              SaveUserInfo({
                companyName: response.companyName,
                googleSignOn: response?.googleSignOn,
              }),
            );
            setPasswordPolicy(response?.passwordValidation);
            setOrganisation(response.companyName);
          }
        }),
      );
    } else if (programId && !organisation) {
      retrieveProgramName();
    }
  }, []);

  const retrieveProgramName = () => {
    dispatch(
      UtilGetProgramName(programId, (response) => {
        dispatch(
          SaveUserInfo({
            programName: response?.programName,
            googleSignOn: response?.googleSignOn,
            samlSignOn: response?.samlSignOn,
            [UpdatableCompanyInfo.PASSWORD_AUTH]: response?.passwordSignOn,
          }),
        );
        const isSAMLAuthOnly = response?.samlSignOn && !response?.passwordSignOn;
        setIsSAMLSSO(isSAMLAuthOnly);
        setIsSSO(isSAMLAuthOnly ?? isSSO);
        setSAMLRedirectUri(response?.samlUri);
        setPasswordPolicy(response?.passwordValidation);
        setOrganisation(response?.programName);
      }),
    );
  };

  const controlSSOUser = (res: IUtilSignUpUserResponse) => {
    const { message, data, success } = res;
    window.history.replaceState({}, document.title, `${signupRedirectUri}`);
    retrieveProgramName();
    if (!success) {
      setApiError(message);
    } else {
      setEmail(data.email);
      setIsSSO(true);
      setIsPreviousUser(data.userExists);
      setSimpleJoin(data.userExists);
      setUserId(data.userId);
      setCheckedEmail(true);
      if (!data.userExists) {
        setFName(data?.firstName);
        setLName(data?.lastName);
        setPhoneNumber(data?.phoneNumber);
      }
    }
  };

  const emailOnBlur = () => {
    if (email !== '') {
      updateWaiting(true);
      dispatch(
        UtilCheckUserExists(email, programId, (userExists) => {
          const userExistsSuccess = userExists?.success;
          setAlreadySignedUpToProgram(userExists?.data?.alreadySignedUp);
          setIsPreviousUser(userExists?.data?.userExists);
          setSimpleJoin(userExistsSuccess && userExists?.data?.userExists);
          setSAMLRedirectUri(userExistsSuccess && userExists?.data?.samlUri);
          setIsSAMLSSO(userExistsSuccess && userExists?.data?.samlSignOn);
          updateWaiting(false);
          setCheckedEmail(true);
        }),
      );
      retrieveProgramName();
    }
  };

  const signUp = () => {
    const errors: string[] = validateFields();
    if (errors.length === 0) {
      updateWaiting(true);
      if (fieldErrors.length > 0) {
        setFieldErrors([]);
      }
      dispatch(
        UtilSignUpUser(
          email,
          password,
          programId,
          fName,
          lName,
          phoneNumber,
          userId,
          isSSO,
          (response) => {
            if (response?.success) {
              dispatch(
                SaveUserInfo({
                  firstName: fName,
                  lastName: lName,
                  username: email,
                  email,
                }),
              );
              setIsPreviousUser(response?.message === 'ALREADY_SIGNED_UP');
              hasSignedUp(true);
            } else {
              dispatch(SaveUserInfo({ username: email }));
              if (!isSSO && response.message === 'Verify email address') {
                setRedirectToVerifyConfirmation(true);
              }
              setApiError(response?.message);
              updateWaiting(false);
            }
          },
        ),
      );
    } else {
      setFieldErrors(errors);
    }
  };

  // Checks that all the values are filled
  const validateFields = (): string[] => {
    const errors: string[] = [];
    if (email === '') {
      errors.push('email');
    }
    if (!simpleJoin) {
      if (fName === '' && !isSSU) {
        errors.push('fName');
      }
      if (lName === '' && !isSSU) {
        errors.push('lName');
      }
      if (organisation === '') {
        errors.push('organisation');
      }
      if (!validatePhoneNumber(phoneNumber) && !isSSU) {
        errors.push('phoneNumber');
      }
      if (!isSSO && !isSAMLSSO) {
        if (password === '' || !simpleValidatePassword(password, passwordPolicy)) {
          errors.push('password');
          setValidatorAnchor(document.getElementById('password'));
        }
      }
    }
    return errors;
  };

  const controlValidator = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!Boolean(validatorAnchor)) {
      setValidatorAnchor(event.currentTarget);
      event.currentTarget.focus();
    }
    event.stopPropagation();
    event.preventDefault();
  };

  const closeValidator = () => {
    if (Boolean(validatorAnchor)) {
      setValidatorAnchor(null);
    }
  };

  const simpleJoinValidation = (): boolean => {
    return Boolean(organisation) && Boolean(email);
  };

  const isDisabled = (): boolean => {
    return !(
      (simpleJoin && checkedEmail && simpleJoinValidation()) ||
      (!simpleJoin && agreeToPolicies)
    );
  };

  return (
    <BackSplashImagery>
      <Grid container direction="row" alignItems="center" justify="center" onClick={closeValidator}>
        {alreadySignedUpToProgram && <Redirect to={GetRoute('login').path} />}
        {redirectToVerifyConfirmation && <Redirect to={GetRoute('confirmRegistration').path} />}
        <Grid item xs={12}>
          <Grid container direction="column" justify="center">
            <Text variant="xl" fontWeight={600}>
              Join program
            </Text>
            <BrancherDottedDivider marginTop={40} marginBottom={30} />
            {apiError && (
              <Text variant="xs" color="red" marginTop={15} marginBottom={15}>
                {apiError}
              </Text>
            )}
            <Grid container item direction="row" xs={12} justify="space-between">
              <Grid item xs={12}>
                <BrancherTextField
                  fullWidth
                  autoCapitalize="off"
                  name="email"
                  type="email"
                  value={email}
                  updateValue={setEmail}
                  placeholder="Your Email Address"
                  label="Email Address"
                  required
                  onBlur={emailOnBlur}
                  error={fieldErrors.includes('email')}
                  helperText={fieldErrors.includes('email') && 'Please enter a valid email!'}
                />
              </Grid>
              {!(simpleJoin || isSSO) && (
                <Grid item xs={12}>
                  <BrancherTextField
                    fullWidth
                    id="password"
                    name="password"
                    type="password"
                    value={password}
                    autoComplete="off"
                    updateValue={setPassword}
                    placeholder="Enter Password"
                    label="Password"
                    required
                    onClick={controlValidator}
                    error={fieldErrors.includes('password')}
                    helperText={
                      fieldErrors.includes('password') && 'Please enter a stronger password!'
                    }
                  />
                  <PasswordValidation
                    anchorEl={validatorAnchor}
                    password={password}
                    passwordValidationRules={passwordPolicy}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <BrancherTextField
                  inputProps={{ readOnly: true }}
                  fullWidth
                  name="organisation"
                  value={organisation}
                  updateValue={setOrganisation}
                  placeholder={companyId ? 'Your Organisation Name' : 'Your Program Name'}
                  label={companyId ? 'Organisation' : 'Program'}
                  required
                  error={fieldErrors.includes('organisation')}
                  helperText={
                    fieldErrors.includes('organisation') &&
                    'Please check the link your organisation sent you!'
                  }
                />
              </Grid>
              {!simpleJoin && (
                <>
                  <Grid item xs={5}>
                    <BrancherTextField
                      fullWidth
                      value={fName}
                      name="firstName"
                      updateValue={setFName}
                      placeholder="First Name"
                      label="First Name"
                      required
                      error={fieldErrors.includes('fName')}
                      helperText={
                        fieldErrors.includes('fName') && 'Please enter a valid first name!'
                      }
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <BrancherTextField
                      fullWidth
                      value={lName}
                      name="lastName"
                      updateValue={setLName}
                      placeholder="Last Name"
                      label="Last Name"
                      required
                      error={fieldErrors.includes('lName')}
                      helperText={
                        fieldErrors.includes('lName') && 'Please enter a valid last name!'
                      }
                    />
                  </Grid>
                </>
              )}
              {!isSSU && !simpleJoin && (
                <Grid item xs={12}>
                  <BrancherPhoneNumberField
                    fullWidth
                    name="phoneNumber"
                    value={phoneNumber}
                    updateValue={setPhoneNumber}
                    placeholder="498 765 432"
                    label="Phone Number"
                    error={fieldErrors.includes('phoneNumber')}
                    helperText={
                      fieldErrors.includes('phoneNumber')
                        ? 'Please enter a valid mobile phone number! Eg; (+61) 498 765 432'
                        : 'eg; (+61) 498 765 432'
                    }
                    required
                  />
                </Grid>
              )}
              {!simpleJoin && (
                <Grid item xs={12}>
                  <Box marginTop={3} />
                  <BrancherTnCs value={agreeToPolicies} setValue={setAgreeToPolicies} />
                </Grid>
              )}
            </Grid>
            <Grid item>
              <Box marginTop={4} alignSelf="center">
                <NextButton onClick={signUp} disabled={isDisabled()} fullWidth>
                  {waiting && !signedUp ? (
                    <CircularProgress color="primary" size={30} />
                  ) : signedUp && (isPreviousUser || isSSO) ? (
                    <Redirect to={GetRoute('login').path} />
                  ) : signedUp && !isPreviousUser && !isSSO ? (
                    <Redirect to={GetRoute('confirmRegistration').path} />
                  ) : (
                    'Submit'
                  )}
                </NextButton>
              </Box>
            </Grid>
            {googleAuth && (
              <Grid item container justify="center">
                <Box marginTop={mobile ? 2 : 4} marginBottom={mobile ? 2 : 3}>
                  <SSOSignUp controlSSOUser={controlSSOUser} type={SSOTypes.GOOGLE} />
                </Box>
              </Grid>
            )}
            {isSAMLSSO && samlRedirectUri && (
              <Grid item container justify="center">
                <Box marginBottom={mobile ? 2 : 3} marginTop={3}>
                  <SAMLSignUp samlRedirectUri={samlRedirectUri} controlSSOUser={controlSSOUser} />
                </Box>
              </Grid>
            )}
            <Box display="inline" textAlign="center" marginTop={3}>
              <Text variant="sm" display="inline">
                Already joined this program?{' '}
              </Text>
              <BrancherRouterLink to={GetRoute('login').path}>
                <Text variant="sm" color="purple" display="inline" textDecoration="underline">
                  Login
                </Text>
              </BrancherRouterLink>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </BackSplashImagery>
  );
};
