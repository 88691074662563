import { ESupportOptions } from '../../PostMatching/Support/SupportRequestForm';
import { ProgramPositions } from '../../QualifyingForm/ProgramPositionOptions';
import { decodeUserToken } from '../../utils/decodeUserToken';
import { IValidationRules } from '../../utils/validators/validatePassword';
import {
  CLEAR_USER_INFO,
  ICompanyInfo,
  IPairInfo,
  IUserInfo,
  ProgramRoleIds,
  SAVE_USER_INFO,
  UpdatableCompanyInfo,
  USER_CELEBRATE,
  USER_LOGOUT,
  USER_REDIRECT,
} from '../reducers/UserInfoReducer';
import { IAPIResponseObject } from './actionTypes/apiTypes';
import { SaveProfileFormData } from './ProfileFormActions';
import {
  BrancherAPIKeyRequest,
  BrancherAuthRequest,
  EAuthEnvs,
  getEnvironmentOrigin,
} from './utils/api-utils';
import { IResource } from '../../PostMatching/Resources/Resources';

export const SaveUserInfo = (userData: IUserInfo) => {
  return {
    type: SAVE_USER_INFO,
    payload: userData,
  };
};

export const SignUserOut = () => {
  return {
    type: USER_LOGOUT,
  };
};

export const ClearUserData = () => {
  return {
    type: CLEAR_USER_INFO,
  };
};

export const SetUserRedirect = (userData: boolean) => {
  return {
    type: USER_REDIRECT,
    payload: userData,
  };
};

export const SetUserCelebrate = (celebrate: boolean) => {
  return {
    type: USER_CELEBRATE,
    payload: celebrate,
  };
};

// Legacy APIs - unnecessary to change endpoint at this stage

// This does a fire and forget on signing the user out
export const UtilSignOut = () => {
  return (dispatch: any, getState: any) => {
    const username = getState().user.username;
    BrancherAuthRequest(
      {
        method: 'post',
        url: 'signout',
        data: {
          username,
        },
      },
      getState(),
    ).then(() => {
      dispatch(SignUserOut());
    });
  };
};

// This confirms the user's registration with a verification code
export const UtilConfirmRegistration = (
  username: string,
  verification: string,
  cb: (a: IAPIResponseObject) => void,
) => {
  return () => {
    BrancherAPIKeyRequest({
      method: 'post',
      url: 'confirmregistration',
      data: {
        username,
        verification,
      },
    })
      .then((response: any) => {
        cb(response.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

// This initialises a verification code for the user when resetting a password
export const UtilInitialiseForgotPassword = (
  username: string,
  cb: (a: IAPIResponseObject) => void,
) => {
  return (dispatch: any) => {
    BrancherAPIKeyRequest({
      method: 'post',
      url: 'forgotpassword',
      data: {
        username,
      },
    })
      .then((response: any) => {
        dispatch(
          SaveUserInfo({
            forgotPasswordEmail: response.data.data?.username,
            passwordPolicy: response.data?.data?.passwordPolicy,
          }),
        );
        cb(response.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

// This updates the password after the user has verified their email in InitialiseForgotPassword
export const UtilUpdatePassword = (
  username: string,
  newPassword: string,
  verification: string,
  cb: (a: IAPIResponseObject) => void,
) => {
  return () => {
    BrancherAPIKeyRequest({
      method: 'post',
      url: 'confirmpassword',
      data: {
        username,
        pwd: newPassword,
        verification,
      },
    })
      .then((response: any) => {
        cb(response.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

/*************************************** VERSION 2 ******************************************/

export interface IGetCompanyInfo extends IAPIResponseObject, ICompanyInfo {
  [UpdatableCompanyInfo.PASSWORD_VALIDATION]?: IValidationRules;
}

// This helps update the password after the user has verified their email in InitialiseForgotPassword
export const UtilGetCompanyInfo = (companyId: string, cb: (a: IGetCompanyInfo) => void) => {
  return () => {
    BrancherAPIKeyRequest({
      method: 'get',
      url: 'v2/company',
      params: JSON.stringify({
        companyId,
      }),
    })
      .then((response: any) => {
        cb(response.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

interface IGetSimpleProgramInfo extends IAPIResponseObject {
  programName: string;
  googleSignOn?: boolean;
  samlSignOn?: boolean;
  passwordSignOn?: boolean;
  samlUri?: string;
  passwordValidation?: IValidationRules;
}

export const UtilGetProgramName = (programId: string, cb: (a: IGetSimpleProgramInfo) => void) => {
  return () => {
    BrancherAPIKeyRequest({
      method: 'get',
      url: 'v2/programname',
      params: JSON.stringify({
        programId,
      }),
    })
      .then((response: any) => {
        cb(response.data.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

// This signs the user up
export const UtilSignUpUser = (
  username: string,
  password: string,
  programId: string,
  firstName: string,
  lastName: string,
  phoneNumber: string,
  userId: string,
  isSSO: boolean,
  cb: (a: IAPIResponseObject) => void,
) => {
  let location = getEnvironmentOrigin();
  if (location === 'dev-mentoring') {
    location = EAuthEnvs.MENTORING;
  }
  return () => {
    BrancherAPIKeyRequest({
      method: 'post',
      url: 'v2/signup',
      data: {
        username,
        email: username,
        pwd: password,
        programId,
        firstName,
        lastName,
        phoneNumber: phoneNumber.replace(/ /g, '').replace(/-/g, ''),
        userId, // only sent with sso
        isSSO,
        agreeToPolicies: true,
        loggedInPlatform: EAuthEnvs.MENTORING,
      },
    })
      .then((response: any) => {
        cb(response.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

interface IMentoringSessionInformation {
  isMatched: boolean;
  hasNumerousRoles: boolean;
  sessionPosition: ProgramPositions;
  sessionPair: IPairInfo;
  sessionRoleId: string;
}

interface ILoginDataResponse
  extends IMentoringSessionInformation,
    Pick<
      IUserInfo,
      | 'companyId'
      | 'programName'
      | 'programId'
      | 'programType'
      | 'firstName'
      | 'lastName'
      | 'username'
      | 'email'
      | 'accessToken'
      | 'refreshToken'
      | ProgramRoleIds.mentee
      | ProgramRoleIds.mentor
      | 'subscription'
      | 'positions'
      | 'mentees'
      | 'mentors'
      | 'modules'
      | 'applicationAlwaysOpen'
      | 'hasAdhocPairing'
      | 'supportEmail'
      | 'mentoringPartnerRequests'
      | 'actions'
      | 'groups'
      | 'tokenExp'
      | 'requiresAdhocPairing'
      | 'maximumMentorAmount'
      | 'maximumMenteeAmount'
      | 'roleLabels'
      | 'requiresApplicationApproval'
      | 'needsReviewing'
      | 'hasAzureCalendar'
      | 'hasGoogleCalendar'
      | 'menteeGetStartedProgress'
      | 'mentorGetStartedProgress'
    >,
    Pick<IGetCompanyInfo, UpdatableCompanyInfo.CUSTOM_LOGO | UpdatableCompanyInfo.COMPANY_NAME> {
  idToken: string;
  userSub: string;
  userId: string;
  formId?: string;
  modules?: { mentee: string[]; mentor: string[] };
  active?: boolean;
  matchingComplete?: boolean;
}

export enum EMFAChallengeNames {
  MFA_SETUP = 'MFA_SETUP',
  SOFTWARE_TOKEN_MFA = 'SOFTWARE_TOKEN_MFA',
}

interface IMFALoginResponse {
  isMFA: boolean;
  challengeName: EMFAChallengeNames;
  session: string;
  keyCode?: string; // only if it's the initial MFA_SETUP
}

export interface IUtilLoginUserResponse extends IAPIResponseObject {
  data: ILoginDataResponse & IMFALoginResponse; // TODO: Refactor this out properly
}

// This authenticates the user
export const UtilLoginUser = (
  username: string,
  password: string,
  programId: string,
  cb: (a: IUtilLoginUserResponse) => void,
) => {
  return (dispatch: any, getState: any) => {
    if (!getState().user.redirected) {
      dispatch(ClearUserData());
    }
    let location = getEnvironmentOrigin();
    if (location === EAuthEnvs.DEV_MENTORING) {
      location = EAuthEnvs.MENTORING;
    }
    BrancherAPIKeyRequest({
      method: 'post',
      url: 'v2/login',
      data: {
        username,
        pwd: password,
        programId,
        lastLoggedIn: new Date().getTime(),
        loggedInPlatform: EAuthEnvs.MENTORING,
      },
    })
      .then((response: any) => {
        cb(response.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

// This verifies the user TOTP setup on initial login
export const UtilMFASetupVerification = (
  validationCode: string,
  accessToken: string,
  cb: (a: IUtilLoginUserResponse) => void,
) => {
  return () => {
    BrancherAPIKeyRequest({
      method: 'post',
      url: 'v2/mfa/verify',
      data: {
        validationCode,
        accessToken,
      },
    })
      .then((response: any) => {
        cb(response.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

// This verifies the user TOTP code to sign in
export const UtilMFASessionVerification = (
  validationCode: string,
  session: string,
  username: string,
  programId: string,
  cb: (a: IUtilLoginUserResponse) => void,
) => {
  return () => {
    BrancherAPIKeyRequest({
      method: 'post',
      url: 'v2/mfa/challenge',
      data: {
        validationCode,
        session,
        username,
        programId,
        loggedInPlatform: EAuthEnvs.MENTORING,
      },
    })
      .then((response: any) => {
        cb(response.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

interface IUtilGetCompanyPrograms extends IAPIResponseObject {
  data?: {
    programs: Array<{ programId: string; programName: string }>;
    company: ICheckUserExists['data'];
  };
}

// This gets all the active programs that a company is running
export const UtilGetCompanyPrograms = (
  username: string,
  pwd: string,
  isSSO: boolean,
  cb: (a: IUtilGetCompanyPrograms) => void,
) => {
  return (dispatch: any) => {
    BrancherAPIKeyRequest({
      method: 'post',
      url: 'v2/companyprograms',
      data: {
        username,
        pwd,
        isSSO,
      },
    })
      .then((response: any) => {
        if (response.data.success) {
          dispatch(SaveUserInfo(response.data.data?.company));
        }
        cb(response.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

interface ICheckUserExists extends IAPIResponseObject {
  data: {
    userExists: boolean;
    companyName: string;
    passwordSignOn?: boolean;
    samlSignOn?: boolean;
    alreadySignedUp: boolean;
    googleSignOn?: boolean;
    oktaSignOn?: boolean;
    samlUri?: string;
  };
}

// This checks whether the user exists and if they are SAML compatible
export const UtilCheckUserExists = (
  username: string,
  programId: string,
  cb: (a: ICheckUserExists) => void,
) => {
  return (dispatch: any) => {
    BrancherAPIKeyRequest({
      method: 'post',
      url: 'v2/userexists',
      data: {
        email: username,
        programId,
      },
    })
      .then((response: any) => {
        dispatch(SaveUserInfo(response.data.data));
        cb(response.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

// This updates the user's email everywhere
export const UtilUpdateUserEmail = (
  newEmail: string,
  password: string,
  cb: (a: IAPIResponseObject) => void,
) => {
  return (dispatch: any, getState: any) => {
    const username = getState().user.username;
    BrancherAuthRequest(
      {
        method: 'post',
        url: 'v2/useremail',
        data: {
          username,
          password,
          newEmail,
        },
      },
      getState(),
    )
      .then((res) => {
        if (res.data.success) {
          dispatch(
            SaveUserInfo({
              ...res.data.data,
              ...decodeUserToken(res.data.data.idToken),
              email: newEmail,
            }),
          );
          dispatch(SaveProfileFormData({ contactEmail: newEmail }));
        }
        cb(res.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

// Standalone GET program call
export const UtilGetProgramInfo = (programId: string, cb: (a: IAPIResponseObject) => void) => {
  return (dispatch: any, getState: any) => {
    BrancherAuthRequest(
      {
        method: 'get',
        url: 'v2/program',
        params: JSON.stringify({
          programId,
        }),
      },
      getState(),
    )
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

interface IUtilSSOValidateSAML extends IAPIResponseObject {
  data: { redirectUri: string };
}

// This get the SAML redirect URI configuration for the user
export const UtilSSOValidateSAML = (username: string, cb: (a: IUtilSSOValidateSAML) => void) => {
  return () => {
    BrancherAPIKeyRequest({
      method: 'post',
      url: 'v2/ssovalidatesaml',
      data: {
        username,
      },
    })
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

interface IUtilSSOSAMLCompatible extends IAPIResponseObject {
  data: {
    compatible: boolean;
    customLogo?: string;
  };
}

// This checks if the current user can SSO SAML login
export const UtilSSOSAMLCompatible = (
  username: string,
  cb: (a: IUtilSSOSAMLCompatible) => void,
) => {
  return () => {
    BrancherAPIKeyRequest({
      method: 'post',
      url: 'v2/samlcompatible',
      data: {
        username,
      },
    })
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

// This gets the SAML user from the token given back from the authorised third party
export const UtilSSOSAMLLogin = (
  token: string,
  programId: string,
  cb: (a: IUtilLoginUserResponse) => void,
) => {
  return (dispatch: any, getState: any) => {
    let location = getEnvironmentOrigin();
    if (location === EAuthEnvs.DEV_MENTORING) {
      location = EAuthEnvs.MENTORING;
    }
    const currState = getState();
    BrancherAuthRequest(
      {
        method: 'post',
        url: 'v2/ssosamllogin',
        data: {
          lastLoggedIn: new Date().getTime(),
          loggedInPlatform: EAuthEnvs.MENTORING,
          programId,
        },
      },
      { ...currState, user: { ...currState.user, IDToken: token } },
    )
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

// This signs up the user after a successful SAML response
export const UtilSSOSAMLSignUp = (
  token: string,
  programId: string,
  cb: (a: IUtilSignUpUserResponse) => void,
) => {
  return (dispatch: any, getState: any) => {
    const currState = getState();
    BrancherAuthRequest(
      {
        method: 'post',
        url: 'v2/samlssosignup',
        data: {
          programId,
        },
      },
      { ...currState, user: { ...currState.user, IDToken: token } },
    )
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

// This verifies the google auth code for login
export const UtilSSOLogin = (code: string, cb: (a: IUtilLoginUserResponse) => void) => {
  return () => {
    BrancherAPIKeyRequest({
      method: 'post',
      url: 'v2/ssologin',
      data: {
        code,
        lastLoggedIn: new Date().getTime(),
        loggedInPlatform: EAuthEnvs.MENTORING,
      },
    })
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

export interface IUtilSignUpUserResponse extends IAPIResponseObject {
  data: {
    email: string;
    programId: string;
    userId?: string;
    firstName?: string;
    lastName?: string;
    userExists?: true;
    emailVerified?: true;
    phoneNumber?: string;
    message?: string;
  };
}

// This verifies the sso auth code for signup
export const UtilSSOSignUp = (
  code: string,
  programId: string,
  cb: (a: IUtilSignUpUserResponse) => void,
) => {
  return () => {
    BrancherAPIKeyRequest({
      method: 'post',
      url: 'v2/ssosignup',
      data: {
        code,
        programId,
      },
    })
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

interface IUtilGetSessionInfo
  extends IGetSimpleProgramInfo,
    Pick<IUserInfo, 'menteeGetStartedProgress' | 'mentorGetStartedProgress'> {
  isMatched: boolean;
  hasNumerousRoles: boolean;
  sessionRoleId: string;
  requiresAdhocPairing: boolean;
  hasAdhocPairing: boolean;
  sessionPair?: IPairInfo;
  sessionPosition?: ProgramPositions;
  mentees?: IPairInfo[];
  mentors?: IPairInfo[];
  peers?: IPairInfo[];
}

// This returns the relative session information given the validated user
export const UtilGetSessionInfo = (
  username: string,
  programId: string,
  cb: (a: IUtilGetSessionInfo) => void,
) => {
  return (dispatch: any, getState: any) => {
    BrancherAuthRequest(
      {
        method: 'get',
        url: 'v2/sessioninfo',
        params: JSON.stringify({
          username,
          programId,
        }),
      },
      getState(),
    )
      .then((res) => {
        cb(res.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

interface IUtilGeSessionPairUserInfo extends IAPIResponseObject {
  data: {
    firstName: string;
    lastName: string;
    userId: string;
  };
}

// This returns related user information about the session pair user
export const UtilGetSessionPairUserInfo = (cb: (a: IUtilGeSessionPairUserInfo) => void) => {
  return (dispatch: any, getState: any) => {
    const sessionPair = getState().user?.sessionPair;
    const position = getState().user?.sessionPosition;
    BrancherAuthRequest(
      {
        method: 'get',
        url: 'v2/userbyrole',
        params: JSON.stringify({
          roleId: sessionPair.roleId,
          programId: sessionPair.programId,
          userPosition:
            position === ProgramPositions.mentor
              ? ProgramPositions.mentee
              : ProgramPositions.mentor,
        }),
      },
      getState(),
    )
      .then((res: { data: IUtilGeSessionPairUserInfo }) => {
        dispatch(
          SaveUserInfo({
            sessionPair: {
              ...sessionPair,
              name: `${res.data.data?.firstName} ${res.data.data?.lastName}`,
              userId: res.data.data?.userId,
            },
          }),
        );
        cb(res.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

// This deletes a particular action from a user list and updates the redux store
export const UtilRemoveUserAction = (userActionId: string, cb: (a: IAPIResponseObject) => void) => {
  return (dispatch: any, getState: any) => {
    const username = getState().user.username;
    BrancherAuthRequest(
      {
        method: 'delete',
        url: 'v2/useraction',
        data: {
          username,
          userActionId,
        },
      },
      getState(),
    )
      .then((res) => {
        dispatch(SaveUserInfo({ actions: res.data.data }));
        cb(res.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

export const UtilCreateSupportRequest = (
  supportOption: ESupportOptions,
  requestText: string,
  cb: (a: IAPIResponseObject) => void,
) => {
  return (dispatch: any, getState: any) => {
    const programId = getState().user.programId;
    const requesterUserId = getState().user.id;
    BrancherAuthRequest(
      {
        method: 'post',
        url: 'v2/supportrequest',
        data: {
          programId,
          supportOption,
          requestText,
          requesterUserId,
        },
      },
      getState(),
    )
      .then((resp: any) => {
        cb(resp.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

interface IUtilGetResources extends IAPIResponseObject {
  data: IResource[];
}

export const UtilGetResources = (cb: (a: IUtilGetResources) => void) => {
  return (dispatch: any, getState: any) => {
    const companyId = getState().user.companyId;
    BrancherAuthRequest(
      {
        method: 'get',
        url: 'v2/resources',
        params: JSON.stringify({
          companyId,
        }),
      },
      getState(),
    )
      .then((resp: any) => {
        // No need to save this to redux, this isn't a frequented page - want it kept up to date too
        cb(resp.data);
      })
      .catch((error) => {
        cb(error);
      });
  };
};
