import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { BrancherDivider } from '../../Components/General/BrancherDivider';
import { BrancherLinkText } from '../../Components/General/BrancherLinkText';
import { LikertScaleText } from '../../Components/General/LikertScaleText';
import { BrancherToggleButton } from '../../Components/InputFields/ToggleButton/BrancherToggleButton';
import { QuestionHeader } from './QuestionHeader';
import { ToggleOptions } from '../../Components/InputFields/ToggleButton/BrancherToggleButton.types';
import { ValueTypes } from '../General/GeneralFormConfig';
import {
  BrancherTextField,
  IBrancherTextField,
} from '../../Components/InputFields/BrancherTextField';
import { Text } from '../../Components/General/Text';

export interface ISelectionControl {
  question: React.ReactNode;
  name: string;
  value: ValueTypes;
  updateValue: (a: Omit<ValueTypes, 'boolean'>) => void;
  options: ToggleOptions[];
  maximumAmount?: number & { mentee?: number; mentor?: number };
  minimumAmount?: number & { mentee?: number; mentor?: number };
  description?: string;
  imageLinks?: Array<{ description: string; link: string }>;
  halfWidth?: boolean;
  thirdWidth?: boolean;
  exclusive?: boolean;
  noQuestion?: boolean;
  searchable?: boolean;
  conditionalTextField?: boolean;
  conditionalTextFieldValue?: string; // If present this opens the conditionalTextField
  likertScale?: boolean;
  textProps?: IBrancherTextField;
  hideOptions?: number[];
}

export const SelectionControl = (props: ISelectionControl) => {
  const {
    question,
    description,
    value,
    updateValue,
    options,
    exclusive,
    name,
    halfWidth,
    thirdWidth,
    maximumAmount,
    minimumAmount,
    searchable,
    conditionalTextField,
    conditionalTextFieldValue = '',
    textProps,
    likertScale,
    noQuestion,
    imageLinks,
    hideOptions,
  } = props;

  const openConditionalTextField: boolean = !!conditionalTextFieldValue
    ? conditionalTextFieldValue === value
    : conditionalTextField;

  return (
    <>
      {!noQuestion && (
        <>
          <Grid item xs={12}>
            <QuestionHeader>{question}</QuestionHeader>
          </Grid>
          <Grid item xs={12}>
            <Text variant="sm">{description}</Text>
          </Grid>
          {imageLinks?.length > 0 && (
            <Grid container justify="center" item>
              <BrancherDivider marginTop={30} marginBottom={10} />
              <Grid container justify="space-between" spacing={3} item xs={12} md={10}>
                {imageLinks.map((linkInfo, index) => (
                  <Grid item xs={12} key={index}>
                    <BrancherLinkText
                      variant="md"
                      href={linkInfo.link}
                      target="_blank"
                      underline="always"
                    >
                      {linkInfo.description}
                    </BrancherLinkText>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}
          {likertScale && (
            <Grid item xs={12}>
              <LikertScaleText />
            </Grid>
          )}
        </>
      )}
      <Grid item xs={12}>
        <BrancherToggleButton
          options={options}
          value={value}
          exclusive={exclusive}
          name={name}
          maximumAmount={maximumAmount}
          minimumAmount={minimumAmount}
          halfWidth={halfWidth}
          thirdWidth={thirdWidth}
          searchable={searchable}
          updateValue={updateValue}
          hideOptions={hideOptions}
        />
      </Grid>
      {openConditionalTextField && (
        <Grid item xs={12}>
          <Box marginTop={-5} />
          <BrancherTextField {...textProps} />
          <Box marginBottom={4} />
        </Grid>
      )}
    </>
  );
};
