import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { Redirect } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { NextButton } from '../Components/InputFields/BrancherButton';
import { IQualifyingFormState } from './QualifyingForm';
import { Text } from '../Components/General/Text';
import { BrancherToggleButton } from '../Components/InputFields/ToggleButton/BrancherToggleButton';
import { IStoreTypes } from '../store/storeTypes';
import {
  BrancherDispatch,
  ClearFormData,
  SaveQualifyFormData,
  UtilGetFormConfiguration,
  UtilGetFormData,
} from '../store/actions';
import { CreateProgramPositionOptions, ProgramPositions } from './ProgramPositionOptions';
import { GetRoute } from '../Components/Routing';

export const ProgramPosition = (props: IQualifyingFormState) => {
  const position = useSelector((state: IStoreTypes) => state.qualify.position);
  const wasRedirected = useSelector((state: IStoreTypes) => state.user.redirected);
  const roleLabels = useSelector((state: IStoreTypes) => state.user?.roleLabels);
  const dispatch = useDispatch();
  const [redirect, setRedirect] = React.useState(false);
  const { step, setStep } = props;

  const CustomisedLabelOptions = CreateProgramPositionOptions(roleLabels);

  React.useEffect(() => {
    if (!wasRedirected) {
      dispatch(ClearFormData());
    } else {
      setRedirect(true);
    }
    BrancherDispatch(
      dispatch,
      UtilGetFormConfiguration(position, false, () => {}),
    );
  }, []);

  const controlNextStep = () => {
    BrancherDispatch(
      dispatch,
      UtilGetFormData(position, (res: any) => {
        if (res.data?.step) {
          setRedirect(true);
        } else {
          setStep(step + 1);
        }
      }),
    );
  };

  const otherPosition = position === ProgramPositions.mentee ? ProgramPositions.mentor : ProgramPositions.mentee;

  return (
    <Grid container justify="center">
      <Grid container direction="column" justify="center" item xs={11} md={10}>
        {redirect && <Redirect to={GetRoute('home').path} />}
        <Grid item>
          <Text variant="xl" align="center" fontWeight={700} marginBottom={10} marginTop={20}>
            What program position are you applying for?
          </Text>
        </Grid>
        <Grid container item justify="center">
          <Text variant="sm" display="inline">
            Choose{' '}
          </Text>
          <Text variant="sm" display="inline" fontWeight={500}>
            '{CustomisedLabelOptions[0].label}'
          </Text>
          <Text variant="sm" display="inline">
            {' '}if you want to{' '}
          </Text>
          <Text variant="sm" display="inline" fontWeight={500}>
            get
          </Text>
          <Text variant="sm" display="inline">
            {' '}
            support, advice and guidance.
          </Text>
        </Grid>
        <Grid container item justify="center">
          <Text variant="sm" display="inline">
            {' '}
            Choose{' '}
          </Text>
          <Text variant="sm" display="inline" fontWeight={500}>
            '{CustomisedLabelOptions[1].label}'
          </Text>
          <Text variant="sm" display="inline">
            {' '}
            if you want to{' '}
          </Text>
          <Text variant="sm" display="inline" fontWeight={500}>
            give
          </Text>
          <Text variant="sm" display="inline" marginBottom={10}>
            {' '}
            support, advice and guidance.
          </Text>
        </Grid>
        <Grid item>
          <BrancherToggleButton
            name="position"
            value={position}
            exclusive
            updateValue={(a: ProgramPositions) => dispatch(SaveQualifyFormData({ position: a }))}
            options={CustomisedLabelOptions}
          />
        </Grid>
        {!!position && (
          <>
            <Grid container item justify="center">
              <Text variant="xs" marginBottom={20}>
                And don't worry, you can always come back and choose to be a {roleLabels[otherPosition]} later as well.
              </Text>
            </Grid>
            <Grid item>
              <NextButton fullWidth onClick={controlNextStep} disabled={!position}>
                Continue as a {roleLabels[position]}
              </NextButton>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};
